<template>
    <div class="page-container">
        <h1>Not Found</h1>
    </div>
</template>

<script>
    export default {
        name: "page-404",
        methods: {

        }
    }
</script>

<style scoped>

</style>